import { Badge } from 'antd';
import { UilTagAlt } from '@iconscout/react-unicons';
import PropTypes from 'prop-types';
import UilExclamationTriangle from '@iconscout/react-unicons/icons/uil-exclamation-triangle';
import React, { useState, useEffect } from 'react';
import { Scrollbars } from '@pezhmanparsaee/react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { UserActionDropDown } from './auth-info-style';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';
import Alert from '../../alerts/alerts';
import { express } from '../../../config/dataService/dataService';

const NotificationBox = React.memo(() => {
  const { rtl } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
    };
  });

  function renderThumb({ style }) {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} />;
  }

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div className="hello" style={thumbStyle} />;
  };

  function renderView({ style }) {
    const customStyle = {
      marginRight: rtl && 'auto',
      [rtl ? 'marginLeft' : 'marginRight']: '-17px',
    };
    return <div style={{ ...style, ...customStyle }} />;
  }

  renderThumb.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  renderView.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  const [notification, setNotifications] = useState([]);
  const [unreadNotifications, setUndreadNotifications] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(0);
  useEffect(() => {
    express
      .get(`/notifications`)
      .then((response) => {
        setNotifications(response.data);

        const initialValue = 0;

        const totalViewed = response.data.reduce((accumulator, currentValue) => {
          return accumulator + !currentValue.clicked;
        }, initialValue);

        setUndreadNotifications(totalViewed);
      })
      .catch((error) => {
        // Handle errors here
        console.log('Error fetching data:', error);
      });
  }, [buttonClicked]);

  function viewedNotifications() {
    express
      .put(`/notifications`)
      .then(() => {
        setButtonClicked(1);
      })
      .catch((error) => {
        // Handle errors here
        console.log('Error fetching data:', error);
      });
  }

  function viewedSingleNotifications(id, type, docnum) {
    express
      .put(`/notifications/${id}`)
      .then(() => {
        setButtonClicked(buttonClicked + 1);
        const newPath = `/${type.toLowerCase()}/${docnum}`;
        window.open(newPath, '_blank');
      })
      .catch((error) => {
        // Handle errors here
        console.log('Error fetching data:', error);
      });
  }

  const content = (
    <UserActionDropDown className="ninjadash-top-dropdown">
      <Heading as="h5" className="ninjadash-top-dropdown__title">
        <span className="title-text">Notifications</span>
        <Badge className="badge-primary" count={notification.length} />
      </Heading>
      <Scrollbars
        autoHeight
        autoHide
        renderThumbVertical={renderThumb}
        renderView={renderView}
        renderTrackVertical={renderTrackVertical}
        renderTrackHorizontal={(props) => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
      >
        {!notification.length === 0 ? (
          <ul className="ninjadash-top-dropdown__nav notification-list">
            <li>
              {notification.map((item) => {
                return (
                  <Link to="#" onClick={() => viewedSingleNotifications(item.id, item.type, item.docnum)}>
                    <div className="ninjadash-top-dropdown__content notifications">
                      <div className="notification-icon bg-primary">
                        <UilTagAlt />
                      </div>
                      <div className="notification-content d-flex">
                        <div className="notification-text">
                          <Heading as="h5">
                            <span>{item.title}</span>
                            <br />
                            <p>{item.message}</p>
                          </Heading>
                          <p className="text-xs">{item.pushDate}</p>
                        </div>
                        <div className="notification-status">{!item.viewed ? <Badge dot /> : ' '}</div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </li>
          </ul>
        ) : (
          <Alert showIcon icon={<UilExclamationTriangle />} message="No Notifications" type="info" />
        )}
      </Scrollbars>
    </UserActionDropDown>
  );

  return (
    <div className="ninjadash-nav-actions__item ninjadash-nav-actions__notification">
      <Popover placement="bottomLeft" content={content} action="click">
        {unreadNotifications > 0 ? (
          <Badge dot offset={[-8, -5]}>
            <Link to="#" className="ninjadash-nav-action-link">
              <ReactSVG onClick={viewedNotifications} src={require('../../../static/img/icon/bell.svg').default} />
            </Link>
          </Badge>
        ) : (
          <Link to="#" className="ninjadash-nav-action-link">
            <ReactSVG onClick={viewedNotifications} src={require('../../../static/img/icon/bell.svg').default} />
          </Link>
        )}
      </Popover>
    </div>
  );
});

export default NotificationBox;
