import { Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { UilApps } from '@iconscout/react-unicons';
import { SettingDropdwon } from './auth-info-style';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';

const Settings = React.memo(() => {
  const content = (
    <SettingDropdwon>
      <div className="setting-dropdwon">
        <Row gutter="10">
          <Col sm={12} xs={24}>
            <a target="_blank" rel="noopener noreferrer" href="https://entec-int.com/">
              <figure className="setting-dropdwon__single d-flex">
                <img style={{ width: '20%' }} src={require('../../../static/img/entec/entecglobe.png')} alt="" />
                <figcaption>
                  <Heading as="h5">Entec Int Website</Heading>
                </figcaption>
              </figure>
            </a>
          </Col>
          <Col sm={12} xs={24}>
            <a target="_blank" rel="noopener noreferrer" href="https://portal.entecglobal.com/auth/login">
              <figure className="setting-dropdwon__single d-flex">
                <img style={{ width: '20%' }} src={require('../../../static/img/entec/Entec-int.png')} alt="" />
                <figcaption>
                  <Heading as="h5">Entec Portal</Heading>
                </figcaption>
              </figure>
            </a>
          </Col>
        </Row>
      </div>
    </SettingDropdwon>
  );

  return (
    <div className="ninjadash-nav-actions__settings">
      <Popover placement="bottomRight" content={content} action="click">
        <Link to="#" className="ninjadash-nav-action-link text-gray-400">
          <UilApps />
        </Link>
      </Popover>
    </div>
  );
});

export default Settings;
