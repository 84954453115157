import axios from 'axios';
import Cookies from 'js-cookie';
import { notification } from 'antd';

let expressUrl = process.env.REACT_APP_API_EXPRESS;
if (process.env.NODE_ENV === 'production') {
  expressUrl = process.env.REACT_APP_API_EXPRESS_LIVE;
}

const express = axios.create({
  baseURL: expressUrl,
  withCredentials: true,
});

const refresh = axios.create({
  baseURL: expressUrl,
  withCredentials: true,
});

function removeCookies() {
  localStorage.removeItem('tt_access_token');
  Cookies.remove('logedIn');
  Cookies.remove('tt_access_token');
  window.location.reload();
}

express.interceptors.request.use(async (config) => {
  const requestConfig = config;
  const { headers } = config;
  let token = localStorage.getItem('tt_access_token');
  if (config.url === '/auth/login') {
    return requestConfig;
  }
  if (token) {
    const tokenData = JSON.parse(atob(token.split('.')[1])); // Decoding the token payload
    const tokenExpiration = tokenData.exp * 1000; // Expiration time in milliseconds
    // Check if the token has expired or will expire soon (e.g., within a minute)
    if (Date.now() >= tokenExpiration - 60000) {
      // Token is either expired or will expire within a minute, refresh it
      try {
        // Make a request to refresh the token
        const refreshResponse = await refresh.post('/auth/refresh');
        const newAccessToken = refreshResponse.data;

        // Update the access token in local storage
        localStorage.setItem('tt_access_token', newAccessToken);

        // Update the token variable with the new access token
        token = newAccessToken;
      } catch (refreshError) {
        removeCookies();
      }
    }

    // Token is valid or refreshed, add it to the request headers
    requestConfig.headers = { ...headers, Authorization: `Bearer ${token}` };
  }

  return requestConfig;
});

let isRefreshing = false;
let refreshSubscribers = [];

function onAccessTokenFetched(accessToken) {
  refreshSubscribers.forEach((callback) => callback(accessToken));
  refreshSubscribers = [];
}

function addSubscriber(callback) {
  refreshSubscribers.push(callback);
}

express.interceptors.response.use(
  (response) => {
    if (response.status === 201 || response.status === 200) {
      if (response.data.TwoFactor) {
        notification.warning({
          message: '',
          description: `${response.data.message}`,
          style: {
            width: 400,
            marginLeft: 335 - 600,
          },
        });
      } else if (response.data.message)
        notification.success({
          message: 'Success!',
          description: `${response.data.message}`,
          style: {
            width: 400,
            marginLeft: 335 - 600,
          },
        });
    } else if (response.status === 204) {
      notification.warning({
        message: 'No Content Found',
        description: ``,
        style: {
          width: 400,
          marginLeft: 335 - 600,
        },
      });
    }
    return response;
  },
  async (error) => {
    const { response } = error;
    const originalRequest = error.config;

    if (response) {
      if (response.status === 401) {
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            const refreshResponse = await refresh.post('/auth/refresh');
            const newAccessToken = refreshResponse.data;

            // Update the access token in local storage
            localStorage.setItem('tt_access_token', newAccessToken);

            // Retry the original request with the new access token
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            onAccessTokenFetched(newAccessToken);

            return axios(originalRequest);
          } catch (refreshError) {
            // Handle refresh token request error
            removeCookies();
          } finally {
            isRefreshing = false;
          }
        }

        // While the token is being refreshed, add this request to a queue
        // and it will be retried once the new access token is available.
        return new Promise((resolve) => {
          addSubscriber((accessToken) => {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            resolve(axios(originalRequest));
          });
        });
      }
      const errorStatusCodes = [400, 404, 409, 403];
      if (response.status === 429) {
        notification.warning({
          message: '',
          description: `Too many requests, please wait a minute before trying again. `,
          style: {
            width: 400,
            marginLeft: 335 - 600,
          },
        });
      }
      if (errorStatusCodes.includes(response.status)) {
        notification.error({
          message: 'Error!',
          description: `${response.data.message}`,
          style: {
            width: 400,
            marginLeft: 335 - 600,
          },
        });
      }
    }

    return Promise.reject(error);
  },
);

export { express };
