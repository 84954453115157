import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const Dashboard = lazy(() => import('../../container/pages/Dashboard/Dashboard'));
const NotFound = lazy(() => import('../../container/pages/404'));
const SalesQte = lazy(() => import('../../container/pages/Quotes/SalesQte'));
const SalesQteItem = lazy(() => import('../../container/pages/Quotes/SalesQteItem'));
const SalesOrders = lazy(() => import('../../container/pages/Orders/SalesOrders'));
const SalesOrdersItem = lazy(() => import('../../container/pages/Orders/SalesOrdersItem'));
const Shipments = lazy(() => import('../../container/pages/Shipments/Shipments'));
const ShipmentsItem = lazy(() => import('../../container/pages/Shipments/ShipmentsItem'));
const Invoices = lazy(() => import('../../container/pages/Invoices/Invoices'));
const InvoicesItem = lazy(() => import('../../container/pages/Invoices/InvoicesItem'));
const ItemTracker = lazy(() => import('../../container/pages/ItemTracker/ItemTracker'));
const MasterSearch = lazy(() => import('../../container/pages/MasterSearch'));
const Faq = lazy(() => import('../../container/pages/Faq/faq'));
const Profile = lazy(() => import('../../container/pages/Profile/Profile'));

function PagesRoute() {
  return (
    <Routes>
      <Route index element={<Dashboard />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="quotes" element={<SalesQte />} />
      <Route path="quotes/:id" element={<SalesQteItem />} />
      <Route path="orders" element={<SalesOrders />} />
      <Route path="orders/:id" element={<SalesOrdersItem />} />
      <Route path="shipments" element={<Shipments />} />
      <Route path="shipments/:id" element={<ShipmentsItem />} />
      <Route path="invoices" element={<Invoices />} />
      <Route path="invoices/:id" element={<InvoicesItem />} />
      <Route path="itemTracker" element={<ItemTracker />} />
      <Route path="masterSearch/:search" element={<MasterSearch />} />
      <Route path="faq" element={<Faq />} />
      <Route path="profile/*" element={<Profile />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
