import Cookies from 'js-cookie';
import actions from './actions';
import { express } from '../../config/dataService/dataService';

const {
  loginBegin,
  loginSuccess,
  loginErr,
  loginBegin2FA,
  loginSuccess2FA,
  loginErr2FA,
  logoutBegin,
  logoutSuccess,
  logoutErr,
} = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await express.post('/auth/login', values);
      if (response.data.errors) {
        dispatch(loginErr(response.data.errors));
      } else if (response.data.TwoFactor) {
        dispatch(loginErr());
        callback({ twoFactor: true });
      } else {
        localStorage.setItem('tt_access_token', response.data);
        Cookies.set('logedIn', true, { expires: 7 });
        dispatch(loginSuccess(true));
        callback({ twoFactor: false });
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const submit2fa = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin2FA());
    try {
      const response = await express.post('/auth/submit2fa', { code: values.join('') });
      if (response.data.errors) {
        dispatch(loginErr2FA(response.data.errors));
      } else if (response.data.TwoFactor) {
        callback(true);
      } else {
        localStorage.setItem('tt_access_token', response.data.token);
        Cookies.set('logedIn', true, { expires: 7 });
        dispatch(loginSuccess2FA(true));
        callback(false);
      }
    } catch (err) {
      console.log(err);
      dispatch(loginErr2FA(err));
    }
  };
};

const register = (values) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await express.post('/register', values);
      if (response.data.errors) {
        dispatch(loginErr('Registration failed!'));
      } else {
        dispatch(loginSuccess(false));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      const token = await Cookies.get('tt_refresh_token');
      await express.delete('/auth/logout', token);
      localStorage.removeItem('tt_access_token');
      Cookies.remove('logedIn');
      Cookies.remove('tt_access_token');
      window.location.reload();

      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      console.log(err);
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, register, submit2fa };
