import {
  UilDashboard,
  UilTag,
  UilTruck,
  UilUser,
  UilParcel,
  UilInvoice,
  UilQuestionCircle,
  UilListUl,
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import { NavTitle } from './Style';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const path = '';
  const pathName = window.location.pathname;
  const pathArray = pathName && pathName !== '/' ? pathName.split(path) : [];
  const mainPath = pathArray.length > 1 ? pathArray[1] : '';
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
        {t('Dashboard')}
      </NavLink>,
      'starter',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
          <UilDashboard />
        </NavLink>
      ),
    ),
    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('Documents')}</NavTitle>,
      'modules',
      null,
      null,
      'group',
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/quotes`}>
        {t('Enquiries / Quotes')}
      </NavLink>,
      'salesQte',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/quotes`}>
          <UilTag />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/orders`}>
        {t('Sales Orders')}
      </NavLink>,
      'salesOrders',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/orders`}>
          <UilParcel />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/shipments`}>
        {t('Shipments')}
      </NavLink>,
      'shipments',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/shipments`}>
          <UilTruck />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/invoices`}>
        {t('Invoices')}
      </NavLink>,
      'invoices',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/invoices`}>
          <UilInvoice />
        </NavLink>
      ),
    ),
    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('Extras')}</NavTitle>,
      'extras',
      null,
      null,
      'group',
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/itemTracker`}>
        {t('Item Tracker')}
      </NavLink>,
      'itemTracker',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/itemTracker`}>
          <UilListUl />
        </NavLink>
      ),
    ),
    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('Support')}</NavTitle>,
      'support',
      null,
      null,
      'group',
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/profile`}>
        {t('Profile')}
      </NavLink>,
      'profile',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/profile`}>
          <UilUser />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/faq`}>
        {t('FAQ')}
      </NavLink>,
      'faq',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/faq`}>
          <UilQuestionCircle />
        </NavLink>
      ),
    ),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
