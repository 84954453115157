import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import { UilQuestionCircle } from '@iconscout/react-unicons';
import { UserOutlined } from '@ant-design/icons';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import Notification from './Notification';
import Search from './Search';
import Settings from './apps';
import { Popover } from '../../popup/popup';
import Customizer from '../../../layout/Customizer/Customizer';
import { logOut } from '../../../redux/authentication/actionCreator';

const path = '';

const AuthInfo = React.memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const SignOut = (e) => {
    e.preventDefault();
    dispatch(logOut(() => navigate('/')));
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <ul className="user-dropdwon__links">
          <li>
            <Link to={`${path}/profile`}>
              <UilUser /> Profile
            </Link>
          </li>
          <li>
            <Link to={`${path}/faq`}>
              <UilQuestionCircle /> FAQ
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <UilSignout /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );
  return (
    <InfoWraper>
      <Search />
      <Customizer />
      <Notification />
      <Settings />
      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <UserOutlined />
            <UilAngleDown />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
});

export default AuthInfo;
