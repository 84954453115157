import UilSearch from '@iconscout/react-unicons/icons/uil-search';
import UilTimes from '@iconscout/react-unicons/icons/uil-times';
import { Input } from 'antd';
import React, { useState, startTransition } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../buttons/buttons';
// import { useSelector } from 'react-redux';

const SearchBar = React.memo(() => {
  const navigate = useNavigate();
  const [inputSearch, setInputSearch] = useState('');
  const [state, setState] = useState({
    openSearch: false,
  });

  const searchInput = (e) => {
    setInputSearch(e.target.value);
  };

  function goMasterSearchPage() {
    startTransition(() => {
      navigate(`masterSearch/${inputSearch}`);
    });
  }

  function goMasterSearchPageEnter(e) {
    if (e.key === 'Enter') {
      startTransition(() => {
        navigate(`masterSearch/${inputSearch}`);
      });
    }
  }

  const openSearchbar = (e) => {
    e.preventDefault();
    setState({
      ...state,
      openSearch: true,
    });
  };
  const closeSearchbar = (e) => {
    e.preventDefault();
    setState({
      ...state,
      openSearch: false,
    });
  };

  const { openSearch } = state;

  return (
    <div
      className={
        openSearch
          ? 'ninjadash-nav-actions__item ninjadash-nav-actions__searchbar show'
          : 'ninjadash-nav-actions__item ninjadash-nav-actions__searchbar'
      }
    >
      <div className="ninjadash-searchbar w-72">
        <Input onKeyDown={goMasterSearchPageEnter} onChange={searchInput} placeholder="Search All Documents" />
      </div>
      <div className="ninjadash-searchbar-button">
        <Button className="mt-6" disabled={inputSearch.length === 0} onClick={goMasterSearchPage}>
          <UilSearch />
        </Button>
      </div>
      <Link to="/" onClick={(e) => openSearchbar(e)} className="ninjadash-search-icon">
        <UilSearch />
      </Link>
      <Link to="/" onClick={(e) => closeSearchbar(e)} className="ninjadash-close-icon">
        <UilTimes />
      </Link>
    </div>
  );
});

export default SearchBar;
