/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import UilPen from '@iconscout/react-unicons/icons/uil-pen';
import UilTimes from '@iconscout/react-unicons/icons/uil-times';
import { useSelector, useDispatch } from 'react-redux';
import { DrawerStyle } from '../../components/drawer/style';
import { changeLayoutMode, changeDirectionMode } from '../../redux/themeLayout/actionCreator';

function Customizer() {
  const { rtl, layoutMode } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      layoutMode: state.ChangeLayoutMode.mode,
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const [state, setState] = useState({
    customizerAction: false,
  });

  const { customizerAction } = state;

  const dispatch = useDispatch();

  useEffect(() => {
    // Retrieve the mode from localStorage on component mount
    const storedMode = localStorage.getItem('layoutMode');
    if (storedMode) {
      dispatch(changeLayoutMode(storedMode));
      if (storedMode === 'darkMode') {
        document.body.classList.add('dark-mode');
      }
    }
  }, [dispatch]);

  const showCustomizer = () => {
    setState({
      customizerAction: !customizerAction,
    });
  };

  const onChildrenDrawerClose = () => {
    setState({
      ...state,
      customizerAction: false,
    });
  };

  const darkmodeActivated = () => {
    document.body.classList.add('dark-mode');
    // Store the mode in localStorage
    localStorage.setItem('layoutMode', 'darkMode');
  };

  const darkmodeDiactivated = () => {
    document.body.classList.remove('dark-mode');
    // Store the mode in localStorage
    localStorage.setItem('layoutMode', 'lightMode');
  };

  const changeLayout = (mode) => {
    dispatch(changeLayoutMode(mode));
    // Store the mode in localStorage
    localStorage.setItem('layoutMode', mode);
  };

  const changeLayoutDirection = (rtlMode) => {
    if (rtlMode) {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'rtl');
    } else {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'ltr');
    }
    dispatch(changeDirectionMode(rtlMode));
  };

  return (
    <>
      <div className="ninjadash-nav-actions__settings">
        <Link
          to="#"
          className="ninjadash-nav-action-link text-gray-400"
          onClick={() => {
            showCustomizer();
          }}
        >
          <UilPen />
        </Link>
      </div>
      <DrawerStyle closable={false} onClose={onChildrenDrawerClose} open={state.customizerAction}>
        <div>
          <div>
            <div>
              <div
                className="cursor-pointer"
                style={{ float: 'right' }}
                to="#"
                onClick={() => {
                  showCustomizer();
                }}
              >
                <UilTimes />
              </div>
              <span>Customizer</span>
              <div className="pt-8">
                <span>Customize your overview page layout</span>
              </div>
            </div>

            <div className="pt-8">
              <div>
                <span>Layouts type</span>

                <ul className="d-flex">
                  <li className="m-auto">
                    <Link
                      className={!rtl ? 'active' : 'deactivate'}
                      onClick={() => {
                        showCustomizer();
                        changeLayoutDirection(false);
                      }}
                      to="#"
                    >
                      <img src={require('../../static/img/customizer/ltr.png')} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={rtl ? 'active' : 'deactivate'}
                      onClick={() => {
                        showCustomizer();
                        changeLayoutDirection(true);
                      }}
                      to="#"
                    >
                      <img src={require('../../static/img/customizer/rtl.png')} alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <span>Light or Dark mode</span>
                <ul className="d-flex">
                  <li className="m-auto">
                    <Link
                      className={layoutMode === 'lightMode' ? 'active' : 'deactivate'}
                      onClick={() => {
                        showCustomizer();
                        darkmodeDiactivated();
                        changeLayout('lightMode');
                      }}
                      to="#"
                    >
                      <img src={require('../../static/img/customizer/light.png')} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={layoutMode === 'darkMode' ? 'active' : 'deactivate'}
                      onClick={() => {
                        showCustomizer();
                        darkmodeActivated();
                        changeLayout('darkMode');
                      }}
                      to="#"
                    >
                      <img src={require('../../static/img/customizer/dark.png')} alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
              {/* <div>
                <span>Navbar type</span>
                <ul className=" d-flex">
                  <li className="m-auto">
                    <Link
                      className={!topMenu ? 'active' : 'deactivate'}
                      onClick={() => {
                        showCustomizer();
                        changeNavbar(false);
                      }}
                      to="#"
                    >
                      <img src={require('../../static/img/customizer/side.png')} alt="" />
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      className={topMenu ? 'active' : 'deactivate'}
                      onClick={() => {
                        showCustomizer();
                        changeNavbar(true);
                      }}
                      to="#"
                    >
                      <img src={require('../../static/img/customizer/top.png')} alt="" />
                    </Link>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </DrawerStyle>

      <span
        className={customizerAction ? 'overlay-dark show' : 'overlay-dark'}
        onClick={() => {
          showCustomizer();
        }}
      />
    </>
  );
}

export default Customizer;
