const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  LOGIN_BEGIN_2FA: 'LOGIN_BEGIN_2FA',
  LOGIN_SUCCESS_2FA: 'LOGIN_SUCCESS_2FA',
  LOGIN_ERR_2FA: 'LOGIN_ERR_2FA',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: (data) => {
    return {
      type: actions.LOGIN_SUCCESS,
      data,
    };
  },

  loginErr: (err) => {
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },

  loginBegin2FA: () => {
    return {
      type: actions.LOGIN_BEGIN_2FA,
    };
  },

  loginSuccess2FA: (data) => {
    return {
      type: actions.LOGIN_SUCCESS_2FA,
      data,
    };
  },

  loginErr2FA: (err) => {
    return {
      type: actions.LOGIN_ERR_2FA,
      err,
    };
  },

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: (data) => {
    return {
      type: actions.LOGOUT_SUCCESS,
      data,
    };
  },

  logoutErr: (err) => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },
};

export default actions;
